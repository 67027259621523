/**
 * accordion.js
 *
 * @author Otto Rask / Sofokus
 */


HTMLElement.prototype.accordion = function (headClass, itemClass)
{
    'use strict';

    var heads = this.querySelectorAll(headClass);
    var items = this.querySelectorAll(itemClass);

    var rawItemClass = itemClass.replace('.', '');

    if (!heads.length || !items.length)  {
        return;
    }

    for (var i = 0; i < items.length; i++) {
        var item = items[i];

        item.dataset['maxh'] = item.offsetHeight;
    }

    var hasOpenClass = function (el)
    {
        return el.className.indexOf('open-item') !== -1;
    };

    var addOpenClass = function (el)
    {
        if (!hasOpenClass(el)) {
            el.className += ' open-item';
        }
    };

    var removeOpenClass = function (el)
    {
        if (hasOpenClass(el)) {
            el.className = el.className.replace(' open-item', '');
        }
    };

    var getAccordionItems = function (el)
    {
        var items = [];

        var next = el.nextElementSibling;

        if (next === undefined || next === null) {
            return;
        }

        // Grab all adjacent accordion items.
        while (
            next !== undefined &&
            next !== null &&
            next.className.indexOf(rawItemClass) !== -1
            ) {
            items.push(next);

            next = next.nextElementSibling;
        }

        return items;
    };

    var closeAccordion = function (el)
    {

        removeOpenClass(el);

        var itemsForEl = el.accordionItems || null;

        if (itemsForEl === null) {
            itemsForEl = getAccordionItems(el);
            el.accordionItems = itemsForEl;
        }

        for (var i = 0; i < itemsForEl.length; i++) {
            var item = itemsForEl[i];

            item.style.height = 0;
        }
    };

    var openAccordion = function (el)
    {

        addOpenClass(el);

        var itemsForEl = el.accordionItems || null;

        if (itemsForEl === null) {
            itemsForEl = getAccordionItems(el);
            el.accordionItems = itemsForEl;
        }

        for (var i = 0; i < itemsForEl.length; i++) {
            var item = itemsForEl[i];

            item.style.height = item.dataset['maxh'] + 'px';
        }
    };

    var toggleAccordion = function (evt)
    {
        var head = evt.target;

        if (hasOpenClass(head)) {
            closeAccordion(head);
        } else {
            openAccordion(head);
        }
    };

    for (var j = 0; j < heads.length; j++) {
        var head = heads[j];

        closeAccordion(head);

        head.style.cursor = 'pointer';

        head.addEventListener('click', toggleAccordion);
    }
};

HTMLElement.prototype.menuAccordion = function ()
{
    'use strict';

    var menuLis = [];

    for (var i = 0; i < this.children.length; i++) {
        var li = this.children[i];

        if (li.className.indexOf('has_children') === -1) {
            continue;
        }

        menuLis.push(li);
    }

    if (!menuLis.length) {
        return;
    }

    var toggleMenu = function (evt)
    {
        evt.preventDefault();

        var li = evt.target;

        while (li.tagName !== 'LI' && li !== null && li !== undefined) {
            li = li.parentNode;
        }

        if (li.tagName !== 'LI') {
            return;
        }

        if (li.className.indexOf('menu-open') === -1) {
            li.className += ' menu-open';
        } else {
            li.className = li.className.replace(' menu-open', '');
        }
    };

    for (var j = 0; j < menuLis.length; j++) {
        var menuli = menuLis[j];

        menuli.querySelector('a').addEventListener('click', toggleMenu);
    }
};
